import React from "react";
import logo from "./img/logo.svg";
import "./logo.css"


class Logo extends React.Component {
    state = {
        visible: true
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmout() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = event => {
        const MAX_SCROLL =  window.innerWidth / 100;
        this.setState({
            visible: !(window.scrollY >= MAX_SCROLL)
        });
    };

    render() {
        return (
            <img id="home_logo" src={logo} alt="logo" style={{opacity: this.state.visible ? "1" : "0"}} />
        );
    }
}


export default Logo;